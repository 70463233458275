import * as React from "react"
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function GitIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34.875 34.004"
      {...props}
    >
      <path
        data-name="Icon awesome-github"
        d="M11.665 27.379c0 .141-.162.253-.366.253-.232.021-.394-.091-.394-.253 0-.141.162-.253.366-.253.211-.021.394.091.394.253zm-2.187-.316c-.049.141.091.3.3.345a.33.33 0 00.436-.141c.042-.141-.091-.3-.3-.366a.361.361 0 00-.436.162zm3.108-.12c-.2.049-.345.183-.323.345.021.141.2.232.415.183s.345-.183.323-.323-.211-.226-.415-.205zM17.212 0A16.86 16.86 0 000 17.156a17.628 17.628 0 0011.918 16.818c.9.162 1.216-.394 1.216-.851 0-.436-.021-2.841-.021-4.317 0 0-4.922 1.055-5.955-2.1 0 0-.8-2.046-1.955-2.573 0 0-1.61-1.1.113-1.083a3.711 3.711 0 012.714 1.819 3.717 3.717 0 005.126 1.47 3.908 3.908 0 011.125-2.37c-3.93-.436-7.9-1.005-7.9-7.77a5.329 5.329 0 011.659-4.141 6.643 6.643 0 01.183-4.774c1.47-.457 4.852 1.9 4.852 1.9a16.611 16.611 0 018.831 0s3.382-2.363 4.852-1.9a6.64 6.64 0 01.183 4.774 5.466 5.466 0 011.814 4.141c0 6.785-4.141 7.327-8.072 7.77a4.158 4.158 0 011.2 3.263c0 2.37-.021 5.3-.021 5.878 0 .457.323 1.013 1.216.851a17.466 17.466 0 0011.8-16.8C34.875 7.403 26.965 0 17.212 0zM6.834 24.25c-.091.07-.07.232.049.366.113.112.274.162.366.07.091-.07.07-.232-.049-.366-.113-.112-.274-.161-.366-.07zm-.759-.57c-.049.091.021.2.162.274a.209.209 0 00.3-.049c.049-.091-.021-.2-.162-.274-.138-.042-.251-.021-.3.05zm2.278 2.5c-.113.091-.07.3.091.436.162.162.366.183.457.07.091-.091.049-.3-.091-.436-.155-.158-.365-.179-.457-.066zm-.8-1.034c-.113.07-.113.253 0 .415s.3.232.394.162a.321.321 0 000-.436c-.1-.158-.283-.228-.395-.137z"
        fill="#fff"
      />
    </SvgIcon>
  )
}

export default GitIcon