import * as React from "react"
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function DribbleIcon(props: SvgIconProps) {
  return (
    <SvgIcon 
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34.875 34.875"
      {...props}
    >
      <path
        data-name="Icon awesome-dribbble"
        d="M17.437 0a17.438 17.438 0 1017.438 17.437A17.457 17.457 0 0017.437 0zm11.529 8.037a14.823 14.823 0 013.363 9.278 34.807 34.807 0 00-10.371-.478c-.4-.987-.786-1.856-1.309-2.926a19.84 19.84 0 008.317-5.874zM27.31 6.318c-.268.382-2.51 3.4-7.806 5.38a79.5 79.5 0 00-5.557-8.719A14.856 14.856 0 0127.31 6.318zM11.105 3.981a95.036 95.036 0 015.522 8.614 55.556 55.556 0 01-13.77 1.814 14.949 14.949 0 018.248-10.428zM2.543 17.46c0-.152 0-.3.008-.455a54.942 54.942 0 0015.307-2.12 50.24 50.24 0 011.208 2.528A23.07 23.07 0 006.372 27.418a14.835 14.835 0 01-3.829-9.958zm5.752 11.75c1.556-3.18 5.778-7.286 11.783-9.334a61.911 61.911 0 013.177 11.295 14.864 14.864 0 01-14.96-1.96zm17.464.6a64.285 64.285 0 00-2.893-10.62 21.861 21.861 0 019.278.637 14.92 14.92 0 01-6.384 9.983z"
        fill="#fff"
      />
    </SvgIcon >
  )
}

export default DribbleIcon
