import { styled } from '@mui/material/styles'

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export const MobileContainer = styled('div')`
@media only screen and (min-width: 1025px){
    display: none;
}
`
  